






















import { ActivityPageUpdated } from '@/+state/activity/events';
import {
  activityActivities,
  activityCount,
  activityLoading,
  activityTableOptions,
} from '@/+state/activity/projections';
import { TABLE_CONFIG } from '@/constants/tableConfig';
import BlueCard from '@/shared/components/BlueCard.vue';
import SimpleTable from '@conversa/bedazzled/src/table/SimpleTable.vue';
import { emit, select } from '@conversa/reflex';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: {
    SimpleTable,
    BlueCard,
  },
  setup() {
    return {
      headers: TABLE_CONFIG.activity.headers,
      tableRows: TABLE_CONFIG.activity.tableRows,
      data: select(activityActivities),
      count: select(activityCount),
      loading: select(activityLoading),
      options: select(activityTableOptions),
      updatePage: page => emit(ActivityPageUpdated({ page })),
    };
  },
});
