var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"simple-table"},[_c('table',{staticClass:"simple-table"},[_c('thead',[(_vm.useHeaders)?_c('tr',[_vm._l((_vm.headers),function(ref){
var content = ref.content;
var value = ref.value;
var sortable = ref.sortable;
return _c('th',{key:content},[_c('div',[_c('p',[_vm._v(_vm._s(content))]),(sortable)?_c('i',{class:{
                'material-icons': true,
                sortable: sortable,
                'hover-icon': sortable && _vm.sortCol !== value,
              },on:{"click":function($event){return _vm.updateSort(value)}}},[_vm._v(" "+_vm._s(_vm.sortCol === value && _vm.sortDir === 'asc' ? 'expand_less' : 'expand_more')+" ")]):_vm._e()])])}),(_vm.link)?_c('th'):_vm._e(),(_vm.expandable)?_c('th'):_vm._e()],2):_vm._e()]),_c('tbody',_vm._l((_vm.data),function(d,i){return _c('div',{key:i,staticClass:"table-row-container"},[_c('tr',{class:{ gray: d.isPast }},[_vm._l((_vm.headers),function(ref,ii){
              var value = ref.value;
              var content = ref.content;
              var dataTitle = ref.dataTitle;
return _c('td',{key:content + ii,attrs:{"data-title":dataTitle || content}},[_vm._t(value,[_vm._v(_vm._s(d[value]))],{"row":d})],2)}),(_vm.link)?_c('td',{staticClass:"link-container"},[_vm._t("linkIcon",null,{"subRow":d})],2):_vm._e(),(_vm.expandable)?_c('td',{staticClass:"button-container"},[_c('button',{class:{
                'expand-button': true,
                selected: _vm.expandedRows.includes(i),
              },on:{"click":function($event){return _vm.modRows(i, d)}}},[_c('i',{staticClass:"material-icons"},[_vm._v(" "+_vm._s(_vm.expandedRows.includes(i) ? 'expand_less' : 'expand_more')+" ")])])]):_vm._e()],2),(_vm.expandable && _vm.expandedRows && _vm.expandedRows.includes(i))?_c('tr',{staticClass:"expanded-row",attrs:{"colspan":_vm.headers.length + 1}},[_c('td',{attrs:{"colspan":_vm.headers.length + 1}},[_vm._t("expanded-row-content",null,{"row":d})],2)]):_vm._e()])}),0)]),(_vm.pagination)?_c('div',{staticClass:"pagination"},[_c('p',[_vm._v(" "+_vm._s(((_vm.paginationData.min) + "-" + (_vm.paginationData.max) + " of " + (_vm.maxItems || 0)))+" ")]),_c('div',{staticClass:"navigation"},[_c('i',{class:{
          'material-icons': true,
          disabled: _vm.paginationData.previousDisabled,
        },on:{"click":function($event){!_vm.paginationData.previousDisabled && _vm.updatePage(1)}}},[_vm._v("first_page")]),_c('i',{class:{
          'material-icons': true,
          disabled: _vm.paginationData.previousDisabled,
        },on:{"click":function($event){!_vm.paginationData.previousDisabled && _vm.updatePage(_vm.page - 1)}}},[_vm._v("chevron_left")]),_c('p',[_vm._v(_vm._s(_vm.page))]),_c('i',{class:{
          'material-icons': true,
          disabled: _vm.paginationData.nextDisabled,
        },on:{"click":function($event){!_vm.paginationData.nextDisabled && _vm.updatePage(_vm.page + 1)}}},[_vm._v("chevron_right")]),_c('i',{class:{
          'material-icons': true,
          disabled: _vm.paginationData.nextDisabled,
        },on:{"click":function($event){!_vm.paginationData.nextDisabled &&
            _vm.updatePage(Math.ceil(_vm.maxItems / _vm.tableRows))}}},[_vm._v("last_page")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }