


















































































































import { computed, defineComponent } from '@vue/composition-api';
import { PropType } from 'vue';
import TableHeader from './table-header.interface';
export default defineComponent({
  props: {
    data: Array,
    expandable: Boolean,
    expandedRows: Array,
    headers: { type: Array as PropType<TableHeader[]> },
    link: Boolean,
    maxItems: Number,
    page: Number,
    pagination: Boolean,
    sortCol: String,
    sortDir: String,
    tableRows: {
      type: Number,
      default: 10,
      validator: function(value) {
        // The value must be greater than 0
        return value > 0;
      },
    },
    useHeaders: { type: Boolean, default: true },
  },
  setup(props, { emit }) {
    return {
      modRows: (i, row) => {
        emit('rowExpanded', { row, index: i });
      },
      updateSort: col => {
        emit('sortInfo', {
          col,
          dir:
            (props.sortCol === col && props.sortDir === 'desc' && 'asc') ||
            'desc',
        });
      },
      updatePage: page => {
        emit('updatePage', page);
      },
      paginationData: computed(() => {
        return {
          min: props.maxItems ? (props.page - 1) * props.tableRows + 1 : 0,
          max: Math.min(props.page * props.tableRows, props.maxItems),
          previousDisabled: props.tableRows <= 0 || props.page <= 1,
          nextDisabled:
            props.tableRows <= 0 ||
            props.page * props.tableRows >= props.maxItems,
        };
      }),
    };
  },
});
