









import { defineComponent } from '@vue/composition-api';
import EmptyState from '@/shared/components/EmptyState.vue';
import ActivitiesTable from './ActivitiesTable.vue';
import { select } from '@conversa/reflex';
import { activityActivities } from '@/+state/activity/projections';

export default defineComponent({
  components: { ActivitiesTable, EmptyState },
  setup() {
    return {
      activities: select(activityActivities),
      content: 'This patient does not have any activities.',
    };
  },
});
